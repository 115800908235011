.pending{
    padding:12px 0px 12px 0px;
    width:120px;
    background: rgba(255, 196, 86, 0.501);
    border-radius:10px;
    color:orange;
    text-align: center;
}

.completed{
    padding:12px 0px 12px 0px;
    background: rgba(0, 239, 32, 0.356);
    color:green;
    width:120px;
    border-radius:10px;
    text-align: center;
}