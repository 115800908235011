.heroImage{
    background-size:contain;
    border-radius:30px
}


.searchHero{
    margin:auto;
    padding:100px
}

.headingText{
    font-size:50px !important;
    font-weight:900 !important
}

@media only screen and (max-width: 600px){
    .searchHero{
        margin:auto;
        padding:50px
    } 
}