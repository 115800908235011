@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

body {
  margin: 0;
  font-family: 'DM Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'DM Sans'
}




@media only screen and (min-width:1000px){
.hide-scroll-bar{
  overflow-x: hidden !important;
}
}



.relative {
  position: relative;
}

.animated-line {
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: moveAround 4s linear infinite;
}

@keyframes moveAround {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


 /* Define the floating animation */
 @keyframes float {
  0% {
    transform: translateY(0); /* Start at normal position */
  }
  50% {
    transform: translateY(-10px); /* Move 10px up */
  }
  100% {
    transform: translateY(0); /* Return to the original position */
  }
}

/* Apply the floating animation to elements with the animate-float class */
.animate-float {
  animation: float 2s ease-in-out infinite; /* 2s duration, easing, infinite loop */
}

.whatsappbutton{
  background: #13c145;
  border-radius: 50%;
  padding: 17px;
  margin: 35px;
  position: fixed;
  bottom: 0;
  right: 0
}