.imageCard{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background: white;
    width:400px;
    border-radius:16px;
    margin:auto auto 30px auto
}
.imageCard .cardtext{
    padding:10px 20px 20px 20px;
    line-height: 32px;
    text-align: start
}
.imageCard .cardtext p{
    margin:0;
    padding:0;
    padding:0px 20px 20px 20px;
    line-height: 28px;
    font-size:30px !important;
    font-weight:600
}

.pictureName{
    font-size:17px
}
.pictureLocation{
   font-size:14px;
   margin-top:-5px
}
/* for sm to md screens */
@media only screen and (max-width:900px) {
    .container{
    display:flex;
    margin-top:-50px;
    justify-content: center;
    text-align:center;
    overflow-anchor: hidden;
}
.imageContainer{
  position: relative;
  text-align: start;
  color: white;
  width:100%;
  margin-bottom:25px
}
.imageStyle{
    border-radius:16px;
    width:100%
}
.textInImageContainer{
  position: absolute;
  bottom: 4px;
  width:100%;
  padding:10px 0px 17px 0px;
  margin:0px -7px 0px 0px;
  background: linear-gradient(to bottom, rgba(255,0,0,0), rgb(12, 11, 11));
  border-radius:16px
}
.textInImageContainer p {
    margin:0
}
.textInImageContainer span{
    text-align:start;
}
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.close {
  display:none;
  color: black;
  font-size: 30px;
  font-weight: bold;
  float:right;
  margin:2px;
  bottom:10
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.imageCard{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background: white;
    width:90%;
    border-radius:16px;
   margin:auto auto 30px auto
}
.imageCard .cardtext{
    padding:20px;
    text-align: start
}
.imageCard .cardtext p{
    margin:0;
    padding:0;
    font-size:20px;
    font-weight:600
}
 
 
}
@media only screen and (min-width:901px) {
    .textPosition{
        margin:100px 60px 0px 60px
    }
    .modalWidth{
        max-width: 500px;
        margin:auto;
        padding:0px 20px
    }
   .grid{
       display:grid;
       grid-template-columns: repeat(2,1fr);
       max-width:900px;
       margin:-50px auto
   }
.imageContainer{
  position: relative;
  text-align: start;
  color: white;
}
.imageStyle{
    border-radius:16px
}
.textInImageContainer{
  position: absolute;
  bottom: 5px;
  width:89%;
  padding:10px 0px 17px 0px;
  margin:0px -7px 0px 0px;
  background: linear-gradient(to bottom, rgba(255,0,0,0), rgb(12, 11, 11));
  border-radius:16px
}
.textInImageContainer p {
    margin:0
}
.textInImageContainer span{
    text-align:start;
}
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.close {
  color: black;
  font-size: 50px;
  font-weight: bold;
  float:right;
  margin:0px 50px 0px 50px;
  bottom:10
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.imageCard{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background: white;
    width:400px;
    border-radius:16px;
    margin:auto auto 30px auto
}
.imageCard .cardtext{
    padding:20px;
    text-align: start
}
.imageCard .cardtext p{
    margin:0;
    padding:0;
    font-size:20px;
    font-weight:600
}
 
 
}
@media only screen and (min-width:1366px) {
   .grid{
       display:grid;
       grid-template-columns: repeat(3,1fr);
       max-width:1100px;
       grid-gap:13px;
       margin:-50px auto
   }
.imageContainer{
  position: relative;
  text-align: start;
  color: white;
}
.imageStyle{
    border-radius:16px;
    width:90%
}
.textInImageContainer{
  position: absolute;
  bottom: 3px;
  width:90%;
  padding:10px 0px 17px 0px;
  margin:0px -7px 0px 0px;
  background: linear-gradient(to bottom, rgba(255,0,0,0), rgb(12, 11, 11));
  border-radius:16px
}
.textInImageContainer p {
    margin:0
}
.textInImageContainer span{
    text-align:start;
}
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.close {
  color: black;
  font-size: 50px;
  font-weight: bold;
  float:right;
  margin:0px 50px 0px 50px;
  bottom:10
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.imageCard{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background: white;
    width:400px;
    border-radius:16px;
    margin:auto auto 30px auto
}
.imageCard .cardtext{
    padding:0px 20px 20px 20px;
    line-height: 32px;
    text-align: start
}
.imageCard .cardtext p{
    margin:0px;
    padding:0px;
    font-size:20px;
    font-weight:600;
    padding:0px 20px 20px 20px;
    line-height: 32px;
}
}


.instructorClass{
  padding:0px;
  margin:0px 
}

.instructorTitle{
font-size:10px
}


