#preliminaryScreen{
    display:none;
    text-align:center
}


.imageSize{
    height:160px;
    width:160px;
    text-align: center;
    margin:50px auto;
} 