@media only screen and (min-width:1200px){
.formContainer{
    margin:80px 80px 0px 80px;
}
}


@media only screen and (max-width:1000px){
    .formContainer{
        margin:60px 20px 0px 20px;
    }
    }



.hideWindow{
    display: none;
}

.showWindow{
    display:block
}