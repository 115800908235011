.wrapper-progressBar {
    width: 100%;
    margin:auto
}

.progressBar {
    font-size:13px
}

.progressBar li {
    list-style-type: none;
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
    color:black
}

.progressBar li:before {
    content: " ";
    line-height: 30px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 10px;
    background-color: white
}

.progressBar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #ddd;
    top: 15px;
    left: -50%;
    z-index: -1;
}

.progressBar li:first-child:after {
    content: none;
}

.progressBar li.active {
    color: white;
}

.progressBar li.active:before {
    border-color: dodgerblue;
    background-color: dodgerblue
}

.progressBar .active:after {
    background-color: dodgerblue;
}


.numberPosition{
    position:absolute;
    top:0;
    left:0;
    margin:6px 0px 0px 124px;
    
    
}




.drop-zone {
    height: 204px;
    padding: 25px;
   display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    color: #cccccc;
    border: 1px dashed blue;
    border-radius: 10px;
  }
  
  .drop-zone--over {
    border-style: solid;
  }
  
  .drop-zone__input {
    display: none;
  }
  
  .drop-zone__thumb {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #cccccc;
    background-size: cover;
    position: relative;
  }
  
  .drop-zone__thumb::after {
    content: attr(data-label);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 0;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.75);
    font-size: 14px;
    text-align: center;
  }
  




@media only screen and (max-width:1000px){
.numberPosition{
    position:absolute;
    top:0;
    left:0;
    margin:4px 0px 0px 44%   
}

.onePosition{
    margin-left:3px
}
}